
import MainLayout from '../layouts/main.vue';

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified page component must be inside the pages folder
 * @param  {string} name  the filename (basename) of the page to load.
 * */
function page (name) {
  return import(`../pages/${name}.vue`);
}

const routes = [
  {
    path: '/',
    redirect: '/latest',
    component: MainLayout,
    children: [
      {
        path: 'latest',
        component: () => page('index'),
      },
    ],
  },
  {
    path: '/404',
    component: MainLayout,
    children: [
      {
        path: 'index',
        component: () => page('404'),
      },
    ],
  },
  {
    path: '/:base',
    redirect: '/:base/index',
    component: MainLayout,
    children: [
      {
        path: 'theme-generator',
        component: () => page('theme-generator'),
      },
      {
        path: ':file',
        component: () => page('index-side-menu'),
      },
    ],
  },
  {
    path: '*',
    redirect: '/404/index',
  },
];


export default routes;
