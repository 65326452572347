<template>
  <div class="wrapper">
    <b-navbar fixed="top" type="dark" variant="primary" style="overflow-x: auto; white-space: nowrap;">
      <b-navbar-brand to="/" style="min-width: 100px;">
        <img id="logo" src="/img/safari-pinned-tab.svg" alt="Fentrica">
        Docs
      </b-navbar-brand>
      <b-navbar-nav>
        <b-nav-item to="/base">
          base &amp; base<b>SAFE</b>
        </b-nav-item>
        <b-nav-item to="/components">
          WebComponents
        </b-nav-item>
        <b-nav-item to="/broker">
          Broker API
        </b-nav-item>
        <b-nav-item to="/orgs">
          Orgs API
        </b-nav-item>
        <b-nav-item to="/measurements">
          Measurements API
        </b-nav-item>
        <b-nav-item to="/hive">
          Hive API
        </b-nav-item>
        <b-nav-item to="/beetle">
          Beetle API
        </b-nav-item>
        <b-nav-item to="/mantis">
          Mantis API
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <router-view />
  </div>
</template>

<script>
// import Sidebar from '../components/sidebar';
export default {
  components: {
    // Sidebar
  }
};
</script>

<style lang="scss">
 #logo {
  height: 3rem;
  width: auto;
  filter: invert(1);
 }
 #app {
   padding-top: 4rem !important;
 }
 .wrapper, .navbar {
    min-width: 700px !important;
 }
</style>
