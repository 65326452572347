import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import * as Moment from 'vue-moment';
import * as _ from 'lodash';
import BootstrapVue from 'bootstrap-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Loading from 'vue-loading-overlay';
import router from './router/index';
import App from './App.vue';
import 'prismjs';
import 'prismjs/themes/prism-okaidia.css';
import 'prismjs/components/prism-scss.min';
import 'prismjs/components/prism-javascript.js';
import 'prismjs/components/prism-bash.js';
import 'prismjs/components/prism-json.js';
import 'prismjs/components/prism-css.js';
import 'prismjs/plugins/autolinker/prism-autolinker.min';
import 'prismjs/plugins/autolinker/prism-autolinker.css';

Vue.config.ignoredElements = ['rapi-doc'];

Vue.component('FaIcon', FontAwesomeIcon);

Vue.use(VueAxios, axios);
Vue.use(Moment);
Vue.use(BootstrapVue);
Vue.use(Loading, {
  backgroundColor: 'transparent',
  loader: 'bars',
  zIndex: 9999,
  color: '#780bb7',
});

Vue.config.productionTip = false;
Vue.axios.defaults.timeout = 5000;
Vue.axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
Vue.axios.defaults.headers.Accept = 'application/json';

let loader;

function showloader () {
  if (!loader) {
    loader = Vue.prototype.$loading.show();
  }
}
function hideloader () {
  if (loader) {
    loader.hide();
    loader = null;
  }
}

Vue.axios.interceptors.request.use((config) => {
  showloader();
  return config;
}, (error) => {
  hideloader();
  return Promise.reject(error);
});

Vue.axios.interceptors.response.use((res) => {
  if (_.has(res, ['headers', 'x-total-count'])) {
    _.set(res, 'count', parseInt(res.headers['x-total-count'], 10));
  }
  hideloader();
  return res;
}, (err) => {
  hideloader();
  return Promise.reject(err);
});

Vue.axios.interceptors.response.use(res => res, (err) => {
  if (err.response && err.response.status === 401) {
    window.location.href = '/auth/login';
  }
  return Promise.reject(err);
});


/* eslint-disable no-new */
new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
